import { Button, Slider } from "antd";
import { useTranslation } from "react-i18next";
import { GamePackage } from "../../components/subscription/GamePackage";

const CheckoutConfiguration = ({ game, value, prices, onChange, onNext }) => {
  const { t } = useTranslation();

  const handleChange = (newValues) => {
    const updatedValues = { ...value, ...newValues };
    // RAM selection is disabled for ARK
    const selectedRam = game?.image !== "ark" ? updatedValues.ram / 1024 : 0;
   
    const priceFound = prices.find(
      (item) =>
        item.ram === selectedRam &&
      item.slots === updatedValues.slots &&
      item.cycle === updatedValues.cycle
    );
    
    const newPrice = priceFound ? priceFound.price : null;

     // updating the ram in case of ARK
     if (game?.image === "ark") {
      updatedValues.ram = priceFound.ram * 1024;
    }

    onChange({ ...updatedValues, price: newPrice });
  };

  return (
    <div className="flex flex-col gap-8 px-5 lg:flex-row">
      <div className="w-screen max-w-xs mx-auto">
        <GamePackage
          readOnly={true}
          // startingFrom
          game={game}
          plan={{
            name: t("plan.custom"),
            price: value.price || 0,
          }}
          entitlements={[
            "4 to 200 players",
            "Individual runtime",
            "Available immediately",
          ]}
          // action={t("plan.customize")}
        />
      </div>
      <div className="flex flex-col flex-grow py-4 rounded shadow shadow-purple-400 bg-background">
        <div className="p-16 space-y-16">
          <div>
            <div className="flex flex-col gap-4 sm:flex-row">
              <h1 className="m-0 font-bold text-7xl text-inherit">
                {value.slots ?? 4}
              </h1>
              <div className="flex flex-col">
                <h1 className="m-0 text-3xl text-inherit">Slots</h1>
                <p className="text-gray-400 text-md">
                  Slots determine how many players can play on the server at the
                  same time. You can increase your game server in the future,
                  but it makes sense to plan generously from the beginning.
                </p>
              </div>
            </div>
            <Slider
              value={value.slots}
              onChange={(slots) => handleChange({ slots })}
              min={4}
              max={200}
              marks={{
                4: {
                  style: {
                    color: "#fff",
                  },
                  label: 4,
                },
                200: {
                  style: {
                    color: "#fff",
                  },
                  label: 200,
                },
              }}
              step={2}
            />
          </div>
          <div className="">
            <div className="flex flex-col gap-4 sm:flex-row">
              <h1 className="m-0 font-bold text-7xl text-inherit">
                {value.cycle ?? 3}
              </h1>
              <div className="flex flex-col">
                <h1 className="m-0 text-3xl text-inherit">Days runtime</h1>
                <p className="text-gray-400 text-md">
                  Slots determine how many players can play on the server at the
                  same time. You can increase your game server in the future,
                  but it makes sense to plan generously from the beginning.
                </p>
              </div>
            </div>
            <Slider
              value={value.cycle}
              onChange={(cycle) => handleChange({ cycle })}
              max={365}
              min={3}
              marks={{
                3: {
                  style: {
                    color: "#fff",
                  },
                  label: 3,
                },
                30: {
                  style: {
                    color: "#fff",
                  },
                  label: 30,
                },
                90: {
                  style: {
                    color: "#fff",
                  },
                  label: 90,
                },
                365: {
                  style: {
                    color: "#fff",
                  },
                  label: 365,
                },
              }}
              step={null}
            />
          </div>
          {game?.image !== "ark" && (
            <div>
              <div className="flex flex-col gap-4 sm:flex-row">
                <h1 className="m-0 font-bold text-7xl text-inherit">
                  {value.ram ? parseInt(value.ram / 1024) : 2}
                </h1>
                <div className="flex flex-col">
                  <h1 className="m-0 text-3xl text-inherit">GB RAM</h1>
                  <p className="text-gray-400 text-md">
                    Slots determine how many players can play on the server at the
                    same time. You can increase your game server in the future,
                    but it makes sense to plan generously from the beginning.
                  </p>
                </div>
              </div>
              <Slider
                value={value.ram}
                onChange={(ram) => handleChange({ ram })}
                max={7168}
                min={2048}
                step={null}
                marks={{
                  2048: {
                    style: {
                      color: "#fff",
                    },
                    label: 2048,
                  },
                  3072: {
                    style: {
                      color: "#fff",
                    },
                    label: 3072,
                  },
                  4096: {
                    style: {
                      color: "#fff",
                    },
                    label: 4096,
                  },
                  7168: {
                    style: {
                      color: "#fff",
                    },
                    label: 7168,
                  },
                }}
              />
            </div>
          )}
        </div>
        <div className="px-16 text-right">
          {onNext && (
            <Button
              ghost
              onClick={() => onNext()}
              disabled={value.price <= 0}
              className="h-auto py-2 text-base font-bold text-white capitalize rounded hover:border-light-1 disabled:opacity-30 disabled:hover:border-white"
            >
              {t("checkout.next")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutConfiguration;
