import { Avatar } from "antd";
import { useUser } from "../../authentication/user"

export function MenuUserThumbnail() {
  const { user } = useUser()

  return (
    <div className="flex gap-2 items-center">
      <Avatar 
        className="flex shrink-0 justify-center items-center text-lg font-semibold align-middle ring-2 ring-light-1/30 bg-avatar-gradient text-white shadow-lg relative overflow-hidden before:absolute before:inset-0 before:bg-avatar-shine before:animate-[shine_3s_ease-in-out_infinite]" 
        src={user?.avatar ?? ""}
      >
        {user?.name?.charAt(0)}
      </Avatar>
      <div className="flex-grow">
        <h1 className="m-0 font-medium text-inherit">{user?.name ?? "user"}</h1>
        <h2 className="m-0 text-sm text-light-60">{user?.email || user?.phone}</h2>
      </div>
    </div>
  );
}
