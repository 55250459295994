import { Alert, Button, Form, message, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { normalize_query_error } from "../../../../components/adhoc/normalize_error";
import { CreateServerForm } from "../forms/CreateServerForm";

export function CreateServer() {
  const { t } = useTranslation();
  const [visible, set_visible] = useState(false);
  const [form] = Form.useForm();

  const client = useQueryClient();

  const create_server = useMutation(
    (values) => {
      const payload = { 
        ...values,
        ram: Number(values.ram) * 1024,
        platform: "admin",
      };
      return axios.post("/subscriptions", payload);
    },
    {
      onSuccess: () => {
        message.success(t("server.create.success"));
        client.invalidateQueries("subscriptions");
        set_visible(false);
        form.resetFields();
      },
    }
  );
  
  const create_server_error = normalize_query_error(create_server.error);

  return (
    <>
      <Button type="primary" onClick={() => set_visible(true)}>
        Create Server
      </Button>
      <Modal
        visible={visible}
        centered
        onCancel={() => set_visible(false)}
        onOk={() => {
          form.validateFields().then((values) => {
            create_server.mutate(values);
          });
        }}
        okText="Create Server"
        okButtonProps={{ loading: create_server.isLoading }}
        destroyOnClose
        className="z-50"
      >
        <CreateServerForm form={form} error={create_server_error?.entities} />
        {create_server_error?.generic && (
          <Alert
            type="error"
            showIcon
            message={t(create_server_error?.generic)}
            className="mt-4"
          />
        )}
      </Modal>
    </>
  );
}
