import { Steps } from "antd";
import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Loader } from "../components/utility/Loader";
import CheckoutConfiguration from "./custom";
import Payment from "./payment";
import OrderConfirmation from "./order/confirmation";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = new URLSearchParams(location.search);
  const game_id = query.get("game");
  const package_id = query.get("package");
  const order_check = query.get("order");
  const subscription_id = query.get("subscription");
  const tap_id = query.get("tap_id");

  const [step, setStep] = useState(0);
  const [subscription, setSubscription] = useState({
    package: package_id,
    game: game_id,
    slots: 4,
    cycle: 3,
    ram: 2048,
    price: 6.99,
    platform: "tappay",
  });

  const { data: packagesData, isLoading: isLoadingPackages } = useQuery(
    "packages",
    () => axios.get("/packages")
  );

  const { data: gamesData, isLoading: isLoadingGames } = useQuery("games", () =>
    axios.get("/games")
  );

  const { data: subscriptionsData, isLoading: isLoadingSubscriptions } = useQuery(
    ["subscriptions", subscription_id],
    () => axios.get(`/subscriptions/${subscription_id}`),
    {
      enabled: !!subscription_id, // Enable for all package types when subscription_id exists
      onError: (error) => {
        console.error('Error fetching subscription:', error);
      }
    }
  );
  
  const pricesQueryKey = useMemo(() => `prices-${game_id}`, [game_id]);
  const { data: pricesData, isLoading: isLoadingPrices } = useQuery(
    pricesQueryKey,
    () => axios.get(`/prices/${game_id}`),
    { enabled: !!game_id } // Only run the query if game_id is not null
  );

  const packages = packagesData?.data || [];
  const games = gamesData?.data || [];
  const prices = pricesData?.data || [];

  const selectedGame = games.find((g) => g._id === game_id);
  const selectedPackage = packages.find((p) => p._id === package_id);

  // Manage steps based on package type, subscription, and payment status
  useEffect(() => {
    if (!subscriptionsData && !package_id) return;

    // Case 1: Successful payment - show confirmation
    if (subscription_id && subscriptionsData?.data?.paid && tap_id) {
      setStep(2);
      return;
    }

    // Case 2: Custom package flow
    if (package_id === "custom") {
      if (!subscription_id) {
        setStep(0); // New custom package - start with configuration
      } else if (order_check) {
        setStep(1); // Returning from payment - show payment page
      }
      return;
    }

    // Case 3: Predefined package - go to payment
    setStep(1);
  }, [package_id, subscription_id, subscriptionsData, order_check, tap_id]);

  // Handle subscription data updates
  useEffect(() => {
    if (subscription_id && subscriptionsData?.data) {
      try {
        const subscriptionData = subscriptionsData.data;
        setSubscription((prev) => ({
          ...prev,
          slots: subscriptionData.slots,
          ram: subscriptionData.ram,
          cycle: subscriptionData.cycle,
          price: subscriptionData.price,
          game: subscriptionData.game || prev.game,
        }));

        // Clean up URL parameters if payment was cancelled
        if (order_check === "true" && !subscriptionData.paid) {
          const newUrl = new URL(window.location.href);
          newUrl.searchParams.delete('order');
          newUrl.searchParams.delete('tap_id');
          window.history.replaceState({}, '', newUrl.toString());
        }
      } catch (error) {
        console.error('Error setting subscription data:', error);
      }
    }
  }, [subscription_id, subscriptionsData, order_check]);

  // update pricing based on selected package
  useEffect(() => {
    if (selectedPackage) {
      setSubscription((prev) => ({
        ...prev,
        package: selectedPackage._id,
        slots: selectedPackage.slots,
        ram: selectedPackage.ram * 1024,
        cycle: selectedPackage.cycle,
        price: selectedPackage.price,
      }));
    }
  }, [selectedPackage]);

  // if no game is selected, redirect to home
  if (!game_id) return <Navigate to="/" />;

  // if games or packages are not loaded, show loader
  if (isLoadingGames || isLoadingPackages || isLoadingPrices || (subscription_id && isLoadingSubscriptions)) return <Loader />;

  const steps = [
    {
      title: t("checkout.configure"),
      content: (
        <CheckoutConfiguration
          game={selectedGame}
          value={subscription}
          prices={prices}
          onChange={(value) =>
            setSubscription((prev) => ({ ...prev, ...value }))
          }
          onNext={() => setStep(1)}
        />
      ),
    },
    {
      title: t("checkout.payment"),
      content: (
        <Payment
          game={selectedGame}
          subscription={subscription}
          onNext={() => setStep(2)}
        />
      ),
    },
    {
      title: t("checkout.confirmation"),
      content: (
        <OrderConfirmation
          subscription={subscription}
          game={selectedGame}
          onNext={() => navigate("/account")}
        />
      ),
    },
  ];

  return (
    <div className="container flex flex-col gap-4 mx-auto px-4 my-8 text-white">
      <Steps current={step}>
        {steps.map((item) => (
          <Steps.Step
            key={item.title}
            title={<span className="text-white capitalize">{item.title}</span>}
          />
        ))}
      </Steps>
      <div className="flex-grow">{steps[step].content}</div>
    </div>
  );
};

export default Checkout;
