import { Navigate, Route, Routes } from "react-router-dom";
import { Games } from "./games";
import { Servers } from "./servers";
import { Customers } from "./customers";
import { Packages } from "./packages";
import { Menu } from "./widgets/Menu";
import { useAuthentication } from "../authentication";
import { Coupons } from "./coupons";
import { Transactions } from "./transactions";
import { Prices } from "./prices";
import { Invoices } from "./invoices";

function Router() {
  return (
    <Routes>
      {/* <Route path="/" element={<_ />} /> */}
      <Route path="/" element={<Servers />} />
      <Route path="/packages" element={<Packages />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/invoices" element={<Invoices />} />
      <Route path="/games" element={<Games />} />
      <Route path="/coupons" element={<Coupons />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/prices" element={<Prices />} />
    </Routes>
  );
}

export function Account() {
  const { is_authenticated } = useAuthentication();

  if (!is_authenticated) {
    return <Navigate to="/authentication" />;
  }

  return (
    <div className="container flex gap-4 p-4 mx-auto my-8 h-full">
      <Menu />
      <div className="flex-grow h-full">
        <Router />
      </div>
    </div>
  );
}
