import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Loader } from "../../components/utility/Loader";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderDetails from "../order/detail";
import PaymentForm from "./form";
import { Link } from "react-router-dom";
import { useAuthentication } from "../../authentication";
import { Input } from "antd";

const stripePromise = loadStripe(
  "pk_test_51MsQbVAEuJ2dDs2iQEcjzSpfCCb5rCzhtFDfh5mDwOgNsKZAIh4VwQCtlHPzp2STWgVfW0AtiI9EVKvy7b3dkUoS00wEYpPiSn"
);

const NeedToLogin = () => {
  return (
    <div className="flex flex-col items-center justify-center py-20">
      <h1 className="text-4xl font-bold text-white">
        You need to Sign in first
      </h1>
      <p className="text-white text-lg">
        Please{" "}
        <Link
          to="/authentication"
          className="text-light-4 hover:text-light-1 hover:underline"
        >
          Sign in
        </Link>
        {" "}
        to continue the payment process
      </p>
    </div>
  );
};

const Payment = ({ game, subscription, onNext }) => {
  const { t } = useTranslation();
  const { is_authenticated } = useAuthentication();
  const [couponCode, setCouponCode] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [error, setError] = useState("");

  // Creating the mutation
  const create_subscription = useMutation(() => {
    const finalPrice = calculateFinalPrice();
    const subscriptionData = {
      ...subscription,
      price: finalPrice,
      // coupon: appliedCoupon ? {
      //   code: couponCode,
      //   discountType: appliedCoupon.discountType,
      //   discountValue: appliedCoupon.discountValue,
      //   originalPrice: subscription.price
      // } : null
    };
    return axios.post("/subscriptions", subscriptionData).then((res) => res.data);
  });

  // Coupon validation mutation
  const validateCoupon = useMutation((code) =>
    axios.post("/coupons/validate", { 
      code,
      gameId: game._id,
      originalPrice: subscription.price
    }).then((res) => res.data)
  );

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      setError("Please enter a coupon code");
      return;
    }

    // if (subscription.price < 100) {
    //   setError("Coupon can only be applied to purchases over 100 SR");
    //   return;
    // }

    try {
      const result = await validateCoupon.mutateAsync(couponCode);
      
      // Calculate if the coupon would make price zero or negative
      const potentialDiscount = result.discountType === 'percentage'
        ? subscription.price * (result.discountValue / 100)
        : result.discountValue;
      
      if (subscription.price - potentialDiscount <= 0) {
        setError("This coupon cannot be applied to this purchase.");
        setAppliedCoupon(null);
        return;
      }

      setAppliedCoupon(result);
      setError("");
    } catch (err) {
      setError(err.response?.data?.message || "Invalid coupon code");
      setAppliedCoupon(null);
    }
  };

  const calculateFinalPrice = () => {
    if (!appliedCoupon) return subscription.price;
    const discount = appliedCoupon.discountType === 'percentage' 
      ? subscription.price * (appliedCoupon.discountValue / 100)
      : appliedCoupon.discountValue;
    
    const finalPrice = Number((subscription.price - discount).toFixed(2));
    return finalPrice <= 0 ? subscription.price : finalPrice;
    // return Number((subscription.price - discount).toFixed(2));
  };

  const formatPrice = (price) => {
    return Number(price).toFixed(2);
  };

  useEffect(() => {
    if (is_authenticated && !create_subscription.isLoading) {
      create_subscription.mutate();
    }
  }, [is_authenticated, appliedCoupon]);

  if (!is_authenticated) {
    return <NeedToLogin />;
  }

  // Accessing the data returned by the mutation
  const checkPlatform = "tappay"; //create_subscription.data;

  if (create_subscription.isLoading) {
    return <Loader />;
  }

  if (create_subscription.isSuccess) {
    const subscriptionId = create_subscription.data._id; // Extracting the _id

    if (checkPlatform === "stripe") {
      return (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: create_subscription.data.paymentIntent.client_secret,
          }}
        >
          <div className="grid grid-cols-2 gap-16">
            <PaymentForm onNext={onNext} subscriptionId={subscriptionId} />
            <div className="px-4 py-10 space-y-8 shadow shadow-purple-400">
              <div className="w-full max-w-xl">
                <OrderDetails game={game} subscription={subscription} />
              </div>
              <div className="w-full border border-light-1"></div>
              <div className="flex justify-between w-full">
                <span className="text-xl capitalize">
                  {t("checkout.price")}
                </span>
                <span className="text-3xl text-light-1">
                  {formatPrice(subscription.price)} SR
                </span>
              </div>
            </div>
          </div>
        </Elements>
      );
    } else {
      return (
        <div>
          <div className="grid grid-cols-2 gap-16">
            <PaymentForm onNext={onNext} subscriptionId={subscriptionId} />
            <div className="px-4 py-10 space-y-8 shadow shadow-purple-400">
              <div className="w-full max-w-xl">
                <OrderDetails game={game} subscription={subscription} />
              </div>
              <div className="w-full border border-light-1"></div>
              

                {/* Coupon Input Section */}
              <div className="space-y-4 max-w-sm w-full ml-auto">
                <div className="flex gap-2">
                  <Input
                    type="text"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    placeholder="Enter coupon code"
                    className="px-3 py-2 rounded bg-white text-black flex-grow"
                  />
                  <button
                    onClick={handleApplyCoupon}
                    disabled={validateCoupon.isLoading}
                    className="px-4 py-2 bg-light-1 text-white rounded hover:bg-light-4 disabled:opacity-50"
                  >
                    {validateCoupon.isLoading ? "Applying..." : "Apply"}
                  </button>
                </div>
                {error && <p className="text-red-500 text-sm">{error}</p>}
                {appliedCoupon && (
                  <p className="text-green-500 text-sm">
                    Coupon applied successfully! {appliedCoupon.discountType === 'percentage' 
                      ? `${appliedCoupon.discountValue}% off`
                      : `${appliedCoupon.discountValue} SR off`}
                  </p>
                )}
              </div>

        
              <div className="flex justify-between w-full">
                <span className="text-2xl capitalize">
                  {t("checkout.price")}
                </span>
                <div className="text-right">
                  {appliedCoupon && (
                    <span className="text-lg text-gray-400 line-through block">
                      {formatPrice(subscription.price)} SR
                    </span>
                  )}
                  <span className="text-3xl text-white">
                    {formatPrice(calculateFinalPrice())} SR
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return null;
};

export default Payment;
