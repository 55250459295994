import ProTable from "@ant-design/pro-table";
import axios from "axios";
import { useQuery } from "react-query";
import { QueryLoader } from "../../components/utility/QueryLoader";
import { useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Space, Tag, Modal, Input, message } from "antd";

export function Customers() {
  const { t } = useTranslation();
  const [filter, set_filter] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [statusReason, setStatusReason] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState("");

  const customers_query = useQuery("customers", () => axios.get("/customers"));
  const customers = customers_query.data?.data || [];

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  const id_game_map = _.keyBy(games, "_id");

  const filtered_customers = useMemo(() => {
    if (!filter.game) {
      return customers;
    }
    return customers.filter((pkg) => {
      return pkg.game === games[filter.game]?._id;
    });
  }, [filter, customers]);

  const handleStatusAction = async () => {
    try {
      await axios.post(`/customers/${selectedUser._id}/${actionType}`, {
        reason: statusReason
      });
      message.success(t("status.update.success"));
      customers_query.refetch();
      setModalVisible(false);
      setSelectedUser(null);
      setStatusReason("");
    } catch (error) {
      message.error(t("status.update.error"));
    }
  };

  const showStatusModal = (user, action) => {
    setSelectedUser(user);
    setActionType(action);
    setModalVisible(true);
  };

  const getStatusColor = (status) => {
    const colors = {
      active: "success",
      suspended: "warning",
      banned: "error"
    };
    return colors[status] || "default";
  };

  return (
   <>
   <QueryLoader query={customers_query}>
      <ProTable
        headerTitle={t("customers")}
        dataSource={filtered_customers}
        search={{
          filterType: "light",
        }}
        request={set_filter}
        columns={[
          {
            title: t("customer.name"),
            dataIndex: "name",
          },
          {
            title: t("customer.email"),
            dataIndex: "email",
          },
          {
            title: t("customer.phone"),
            dataIndex: "phone",
          },
          {
            title: t("customer.status"),
            dataIndex: "status",
            render: (status) => (
              <Tag color={getStatusColor(status)}>
                {t(`status.${status}`)}
              </Tag>
            ),
          },
          {
            title: t("customer.actions"),
            dataIndex: "actions",
            search: false,
            render: (_, record) => (
              <Space>
                {record.status !== "banned" && (
                  <Button 
                    danger 
                    onClick={() => showStatusModal(record, "ban")}
                  >
                    {t("action.ban")}
                  </Button>
                )}
                {record.status !== "suspended" && record.status !== "banned" && (
                  <Button 
                    type="warning" 
                    onClick={() => showStatusModal(record, "suspend")}
                  >
                    {t("action.suspend")}
                  </Button>
                )}
                {record.status !== "active" && (
                  <Button 
                    type="primary" 
                    onClick={() => showStatusModal(record, "activate")}
                  >
                    {t("action.activate")}
                  </Button>
                )}
              </Space>
            ),
          },
          {
            title: t("customer.subscriptions"),
            dataIndex: "subscriptions",
            search: false,
          }
        ]}
      />
    </QueryLoader>
    <Modal
      title={t(`status.${actionType}.title`)}
      visible={modalVisible}
      onOk={handleStatusAction}
      onCancel={() => {
        setModalVisible(false);
        setSelectedUser(null);
        setStatusReason("");
      }}
      destroyOnClose
      className="z-50"
    >
      {actionType !== "activate" && (
        <Input.TextArea
          placeholder={t("status.reason.placeholder")}
          value={statusReason}
          onChange={(e) => setStatusReason(e.target.value)}
          rows={4}
        />
      )}
    </Modal>
   </>
  );
}
