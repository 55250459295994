import { Card, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LocaleSwitcher } from "./widgets/LocaleSwitcher";
import DownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { NavbarAuthentication } from "./widgets/NavbarAuthentication";
import ListIcon from "@mui/icons-material/MenuRounded";

function ListNavbar() {
  return (
    <div className="flex gap-2 sm:hidden">
      <NavbarAuthentication />
      <Dropdown
        className=""
        overlay={
          <div className="px-4 py-2 space-y-4 text-white bg-background">
            <NavbarLinks />
            <NavbarActions />
          </div>
        }
      >
        <div className="flex gap-2 justify-center items-center">
          <ListIcon className="text-white" />
        </div>
      </Dropdown>
    </div>
  );
}

function NavbarLinks() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 capitalize sm:flex-row xl:items-center 2xl:gap-16 xl:gap-16 lg:gap-8 md:gap-4 sm:gap-4 xl:text-lg text-md">
      <Link to="/" className="text-inherit">
        {t("homepage.home")}
      </Link>

      <Dropdown
        overlay={
          <div className="py-2 space-y-4 w-52 rounded bg-background">
            {[
              {
                label: t("homepage.about"),
                link: "/aboutus",
              },
            ].map((link, i) => (
              <Link
                to={link.link}
                className="px-4 text-white capitalize cursor-pointer hover:text-light-1"
                key={i}
              >
                {`${t(link.label)}`}
              </Link>
            ))}
          </div>
        }
      >
        <a className="text-inherit">
          <span>{t("homepage.company")}</span>
          <DownIcon />
        </a>
      </Dropdown>
      
      <Link to="/contact" className="text-inherit">
        {t("homepage.support")}
      </Link>
    </div>
  );
}

function NavbarActions() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 items-stretch sm:flex-row xl:items-center 2xl:gap-12 xl:gap-8 lg:gap-4 xl:text-lg text-md">
      <LocaleSwitcher />
      <div className="hidden md:block">
        <NavbarAuthentication />
      </div>
    </div>
  );
}

export function Navbar() {
  const { t } = useTranslation();

  return (
    <div className="container flex flex-wrap gap-2 justify-between items-center p-4 px-4 py-2 mx-auto md:py-4">
      <Link to="/" className="flex-shrink-0">
        <img src="/logo/serferaty.png" alt="logo" className="h-20 2xl:h-24" />
      </Link>
      <div className="hidden text-white sm:flex">
        <NavbarLinks />
      </div>
      <div className="hidden text-white sm:flex">
        <NavbarActions />
      </div>
      <ListNavbar />
    </div>
  );
}
