import { Tag } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { QueryLoader } from "../../components/utility/QueryLoader";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProTable from "@ant-design/pro-table";

const status_color_map = {
  INITIATED: "processing",
  CANCELLED: "error",
  CAPTURED: "success",
  DECLINED: "error",
  FAILED: "error",
  PENDING: "warning",
};

function InvoicesList() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({});

  const invoicesQuery = useQuery("invoices", () =>
    axios.get("/invoices", {
      params: {
        limit: 30 // Show last 30 records
      }
    })
  );

  const invoices = invoicesQuery.data?.data?.data || [];
  const totalCount = invoicesQuery.data?.data?.count || 0;

  const filteredInvoices = useMemo(() => {
    if (!filter.status) {
      return invoices;
    }
    return invoices.filter((invoice) => invoice.status === filter.status);
  }, [filter, invoices]);

  return (
    <QueryLoader query={invoicesQuery}>
      <ProTable
        headerTitle={t("invoices")}
        dataSource={filteredInvoices}
        pagination={false} // Disable pagination since we're showing last 30
        search={{
          filterType: "light",
        }}
        request={setFilter}
        columns={[
          {
            title: t("invoice.id"),
            dataIndex: "id",
            copyable: true,
            search: false,
            ellipsis: true,
          },
          {
            title: t("invoice.amount"),
            dataIndex: "amount",
            search: false,
            render: (value, record) => `${value} ${record.currency}`,
          },
          {
            title: t("invoice.status"),
            dataIndex: "status",
            valueType: "select",
            valueEnum: {
              INITIATED: { text: t("invoice.status.initiated"), status: "Processing" },
              CANCELLED: { text: t("invoice.status.cancelled"), status: "Error" },
              CAPTURED: { text: t("invoice.status.captured"), status: "Success" },
              DECLINED: { text: t("invoice.status.declined"), status: "Error" },
              FAILED: { text: t("invoice.status.failed"), status: "Error" },
              PENDING: { text: t("invoice.status.pending"), status: "Warning" },
            },
            render: (_, record) => (
              <Tag color={status_color_map[record.status]}>
                {t(`invoice.status.${record.status.toLowerCase()}`)}
              </Tag>
            ),
          },
          {
            title: t("invoice.customer"),
            dataIndex: ["customer", "first_name"],
            search: false,
          },
          {
            title: t("invoice.email"),
            dataIndex: ["customer", "email"],
            search: false,
            ellipsis: true,
          },
          {
            title: t("invoice.date"),
            dataIndex: ["transaction", "date", "created"],
            search: false,
            render: (value) => new Date(parseInt(value)).toLocaleString(),
          },
        ]}
      />
    </QueryLoader>
  );
}

export function Invoices() {
  return (
    <div className="flex-grow">
      <InvoicesList />
    </div>
  );
}