import {
  ProFormDatePicker,
  ProFormDigit,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { ImageUpload } from "../../../components/input/ImageUpload";
import _ from "lodash";
import { useQuery } from "react-query";
import axios from "axios";

const coupon_types = ["percentage", "amount"];

export function CreateCouponForm({ form, error }) {
  const { t } = useTranslation();

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  return (
    <Form form={form} layout="vertical">
            <ProFormText
        name="code"
        label={t("coupon.code")}
        rules={[{ required: true }]}
        validateStatus={_.get(error, "code") ? "error" : undefined}
        help={_.get(error, "code") ? error.code : undefined}
        placeholder={t("code_placeholder")}
      />
       <ProFormSelect
        name="type"
        label={t("coupon.type")}
        options={coupon_types.map((type) => ({
          label: t(`coupon.type.${type}`),
          value: type,
        }))}
        rules={[{ required: true, message: t("type_required") }]}
        validateStatus={_.get(error, "type") ? "error" : undefined}
        help={_.get(error, "type") ? error.type : undefined}
        placeholder={t("coupon.type.placeholder")}
        />
      <ProFormDigit
        name="value"
        label={t("coupon.value")}
        rules={[{ required: true }]}
        validateStatus={_.get(error, "value") ? "error" : undefined}
        help={_.get(error, "value") ? error.value : undefined}
        placeholder={t("coupon.value.placeholder")}
      />
       <ProFormSelect
        name="games"
        label={t("coupon.games")}
        options={games.map((game) => ({
          label: game.name,
          value: game._id,
        }))}
        rules={[]}
        validateStatus={_.get(error, "games") ? "error" : undefined}
        help={_.get(error, "games") ? error.games : undefined}
        placeholder={t("coupon.games.placeholder")}
        mode="multiple"
        />
      <ProFormDatePicker
        name="expiry"
        label={t("coupon.expiry")}
        rules={[{ required: true, message: t("expiry_required") }]}
        validateStatus={_.get(error, "expiry") ? "error" : undefined}
        help={_.get(error, "expiry") ? error.expiry : undefined}
        placeholder={t("expiry_placeholder")}
      />
    </Form>
  );
}
