import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as firebase_ui from "firebaseui";
import { createContext, useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import "./index.css";
import { Loader } from "../components/utility/Loader";
import { application } from "./firebase";
import { useTranslation } from "react-i18next";

const firebase_ui_configuration = {
  signInSuccessUrl: "/",
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      emailLinkSignIn: true,
    },
    // {
    //   provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    //   recaptchaParameters: {
    //     type: "image", // 'audio'
    //     size: "normal", // 'invisible' or 'compact'
    //   },
    //   defaultCountry: "MA",
    // },
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      clientId:
        "503709876744-0ghu92fbi1di1g79qae1f91fvlh5jnf9.apps.googleusercontent.com",
      customParameters: {
        prompt: 'select_account'
      }
    },
  ],
  callbacks: {
    signInFailure: (error) => {
      console.log("Sign-in failure:", error)
      return Promise.resolve();
    },
    signInSuccessWithAuthResult: (authResult) => {
      console.log("Sign-in success:", authResult);
      return true;
    }
  },
  credentialHelper: firebase_ui.auth.CredentialHelper.GOOGLE_YOLO,
  signInFlow: 'popup',
};

export const authentication = application.auth();

// add axios interceptor to handle authentication access token
axios.interceptors.request.use(async (config) => {
  if (authentication.currentUser) {
    const access_token = await authentication.currentUser.getIdToken();
    config.headers.Authorization = access_token;
  }
  return config;
});

const context = createContext();

export function AuthenticationProvider({ children }) {
  const [is_authenticated, set_is_authenticated] = useState();

  useEffect(() => {
    authentication.onAuthStateChanged(
      (user) => {
        set_is_authenticated(!!user);
      },
      (error) => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { i18n } = useTranslation();

  if (is_authenticated === undefined) return <Loader />;

  return (
    <context.Provider
      value={{
        is_authenticated,
        is_loading: is_authenticated === undefined,
        signout: () => {
          authentication.signOut();
        },
      }}
    >
      <div className="h-screen" dir={i18n.language === "ar" || i18n.language === "ur" ? "rtl" : "ltr"}>{children}</div>
    </context.Provider>
  );
}

export const useAuthentication = () => useContext(context);

function Authentication() {
  const { is_authenticated, is_loading } = useAuthentication();
  const { t } = useTranslation();

  const ui =
    firebase_ui.auth.AuthUI.getInstance() ||
    new firebase_ui.auth.AuthUI(authentication);

  useEffect(() => {
    if (is_authenticated === false) {
      ui.start("#firebaseui-auth-container", firebase_ui_configuration);
    }
  }, [is_authenticated]);

  if (is_authenticated) return <Navigate to="/" />;
  if (is_loading) return <Loader />;

  return (
    <div className="flex flex-col justify-center items-center w-full h-screen bg-background">
      <div className="space-y-8 w-screen max-w-md">
        <div className="flex justify-between items-end px-6">
          <Link to="/">
            <img src="/logo/serferaty.png" alt="logo" className="h-24" />
          </Link>
          <h1 className="text-lg text-white capitalize">
            {t("authentication")}
          </h1>
        </div>
        <div className="w-full rounded bg-light-1 h-0.5" />
        <div className="flex flex-col flex-grow gap-8 justify-center items-center">
          <div
            id="firebaseui-auth-container"
            className={ui.isPendingRedirect() ? "hidden" : ""}
          ></div>
          {ui.isPendingRedirect() && <Loader />}
          <span className="text-xs text-white">
            by signing in, you agree to our{" "}
            <Link to="/tos">terms of service</Link> and{" "}
            <Link to="/privacy">privacy policy</Link>.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Authentication;
