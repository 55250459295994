import { ProFormDigit, ProFormSelect, ProFormRadio } from "@ant-design/pro-components";
import { Form, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axios from "axios";
import _ from "lodash";
import { useState, useEffect } from "react";

export function CreateServerForm({ form, error }) {
  const { t } = useTranslation();
  const [selectedGame, setSelectedGame] = useState("");
  const [packageType, setPackageType] = useState("custom");
  const [selectedPackage, setSelectedPackage] = useState(null);

  const games_query = useQuery("games", () => axios.get("/games"));
  const games = games_query.data?.data || [];

  const customers_query = useQuery("customers", () => axios.get("/customers"));
  const customers = customers_query.data?.data || [];

  const packages_query = useQuery(
    ["packages", selectedGame],
    () => axios.get("/packages/search", { params: { gameId: selectedGame } }),
    { enabled: !!selectedGame }
  );

  const packages = packages_query.data?.data || [];

  const handlePackageChange = (packageId) => {
    const selected = packages.find(pkg => pkg._id === packageId);
    setSelectedPackage(selected);
    if (selected) {
      // Set all package details in form
      form.setFieldsValue({
        slots: selected.slots,
        ram: selected.ram,
        cycle: selected.cycle,
        price: selected.price,
        package: selected._id,
        // Include any other fields from the package that you need
      });
    }
  };

  // Reset form values when package type changes
  useEffect(() => {
    if (packageType === 'custom') {
      setSelectedPackage(null);
      form.setFieldsValue({
        package: undefined,
        slots: undefined,
        ram: undefined,
        cycle: undefined,
        price: undefined,
      });
    }
  }, [packageType, form]);

  return (
    <Form form={form} layout="vertical">
      <ProFormSelect
        name="game"
        label={t("game")}
        options={games.map((game) => ({
          label: game.name,
          value: game._id,
        }))}
        fieldProps={{
          onChange: (e) => setSelectedGame(e),
        }}
        rules={[{ required: true, message: t("game_required") }]}
        validateStatus={_.get(error, "game") ? "error" : undefined}
        help={_.get(error, "game") ? error.game : undefined}
        placeholder={"Select a game"}
      />

      <ProFormSelect
        name="customer"
        label={"Customer"}
        options={customers.map((customer) => ({
          label: customer.name,
          value: customer._id,
        }))}
        rules={[{ required: true, message: "Customer is required" }]}
        validateStatus={_.get(error, "customer") ? "error" : undefined}
        help={_.get(error, "customer") ? error.game : undefined}
        placeholder={"Select a customer"}
      />

      <ProFormRadio.Group
        name="packageType"
        label={"Package Type"}
        initialValue="custom"
        options={[
          { label: "Custom", value: "custom" },
          { label: "Predefined", value: "predefined" },
        ]}
        fieldProps={{
          onChange: (e) => setPackageType(e.target.value),
        }}
      />

      {packageType === "custom" ? (
        <Space direction="vertical" className="w-full">
          <ProFormDigit
            name="slots"
            label={t("package.slots")}
            rules={[{ required: true, message: t("slots_required") }]}
            validateStatus={_.get(error, "slots") ? "error" : undefined}
            help={_.get(error, "slots") ? error.slots : undefined}
            placeholder={t("slots_placeholder")}
          />
          <ProFormDigit
            name="ram"
            label={t("package.ram")}
            rules={[{ required: true, message: t("ram_required") }]}
            validateStatus={_.get(error, "ram") ? "error" : undefined}
            help={_.get(error, "ram") ? error.ram : undefined}
            placeholder={t("ram_placeholder")}
          />
          <ProFormDigit
            name="cycle"
            label={t("package.cycle")}
            rules={[{ required: true, message: t("cycle_required") }]}
            validateStatus={_.get(error, "cycles") ? "error" : undefined}
            help={_.get(error, "cycles") ? error.cycles : undefined}
            placeholder={t("cycles_placeholder")}
          />
          <ProFormDigit
            name="price"
            label={"Price"}
            rules={[{ required: true, message: "Price is required" }]}
            validateStatus={_.get(error, "price") ? "error" : undefined}
            help={_.get(error, "price") ? error.price : undefined}
            placeholder={"Price"}
          />
        </Space>
      ) : (
        <Space direction="vertical" className="w-full">
          <ProFormSelect
            name="package"
            label={"Package"}
            options={packages.map((pkg) => ({
              label: pkg.name,
              value: pkg._id,
            }))}
            fieldProps={{
              onChange: handlePackageChange
            }}
            rules={[{ required: true, message: "Package is required" }]}
            validateStatus={_.get(error, "package") ? "error" : undefined}
            help={_.get(error, "package") ? error.package : undefined}
            placeholder={"Select a package"}
          />

          {selectedPackage && (
            <>
              <ProFormDigit
                name="slots"
                label={t("package.slots")}
                disabled
                placeholder={t("slots_placeholder")}
              />
              <ProFormDigit
                name="ram"
                label={t("package.ram")}
                disabled
                placeholder={t("ram_placeholder")}
              />
              <ProFormDigit
                name="cycle"
                label={t("package.cycle")}
                disabled
                placeholder={t("cycles_placeholder")}
              />
              <ProFormDigit
                name="price"
                label={"Price"}
                disabled
                placeholder={"Price"}
              />
            </>
          )}
        </Space>
      )}
    </Form>
  );
}
