import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuthentication } from "../../authentication";
import { Loader } from "../../components/utility/Loader";
import { useUser } from "../../authentication/user";
import DownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Dropdown } from "antd";

import SignoutIcon from "@mui/icons-material/ExitToAppRounded";
import ServersIcon from "@mui/icons-material/DnsRounded";
import InvoicesIcon from "@mui/icons-material/ReceiptRounded";
import { MenuUserThumbnail } from "../../account/widgets/MenuUserThumbnail";

export function NavbarUserThumbnail() {
  const { user } = useUser();
  const { signout } = useAuthentication();
  const { t } = useTranslation();

  const menu = [
    // {
    //   label: t("navbar.account"),
    //   icon: <AccountIcon />,
    //   link: "/account",
    // },
    {
      label: t("navbar.servers"),
      icon: ServersIcon,
      link: "/account",
    },
    {
      label: t("navbar.invoices"),
      icon: InvoicesIcon,
      link: "/account/invoices",
    },
    {
      label: t("navbar.signout"),
      icon: SignoutIcon,
      onClick: signout,
    },
  ];

  return (
    <Dropdown
      placement="bottomRight"
      trigger="click"
      overlay={
        <div className="min-w-[240px] text-white rounded-lg border border-light-3/20 bg-dark-2 shadow-xl">
          <div className="px-4 py-3 border-b border-light-3/20 bg-dark-1">
            <MenuUserThumbnail />
          </div>
          <div className="flex flex-col py-2">
            {menu.map((item) => {
              const _item = (
                <div
                  key={item.label}
                  className="flex gap-3 items-center px-4 py-2.5 text-base cursor-pointer hover:bg-light-1/10 transition-all duration-200 group"
                  onClick={item.onClick}
                >
                  <item.icon className="text-2xl text-light-60 group-hover:text-light-1" />
                  <span className="flex-grow text-light-20 group-hover:text-white">
                    {item.label}
                  </span>
                </div>
              );

              if (item.link) {
                return (
                  <Link
                    className="text-inherit"
                    to={item.link}
                    key={item.label}
                  >
                    {_item}
                  </Link>
                );
              }
              return _item;
            })}
          </div>
        </div>
      }
      dropdownRender={menu => (
        <div className="duration-200 animate-in fade-in">
          {menu}
        </div>
      )}
    >
      <div className="flex gap-2 items-center text-white transition-colors duration-200 cursor-pointer">
        <span className="truncate">{user?.name ?? t("unnamed")}</span>
        <DownIcon />
      </div>
    </Dropdown>
  );
}

export function NavbarAuthentication() {
  const { t } = useTranslation();
  const { is_authenticated } = useAuthentication();

  switch (is_authenticated) {
    case undefined:
      return <Loader />;
    case true:
      return <NavbarUserThumbnail />;
    case false:
      return (
        <Link className="text-white capitalize" to="/authentication">
          {t("homepage.signup")}
        </Link>
      );
    default:
      return null;
  }
}
