import ProTable from "@ant-design/pro-table";
import { Button } from "antd";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { QueryLoader } from "../../components/utility/QueryLoader";
import { CreateServer } from "./admin/widgets/CreateServer";
import { useUser } from "../../authentication/user";

const status_color_map = {
  online: "green",
  offline: "red",
  starting: "blue",
  stopping: "blue",
};

export function Servers() {
  const servers_query = useQuery("subscriptions", () =>
    axios.get("/subscriptions")
  );
  const { granted } = useUser();

  const servers = servers_query.data?.data || [];

  return (
    <div className="w-full h-full">
      <QueryLoader query={servers_query}>
        <ProTable
          search={false}
          headerTitle="Servers"
          toolBarRender={() => [
            granted("create", "server") && <CreateServer />
          ]}
          dataSource={servers}
          rowKey="_id"
          columns={[
            {
              title: "Game",
              render: (_, record) => (
                <div className="flex items-center">
                  <div
                    className="mr-2 h-8 rounded aspect-square"
                    style={{
                      backgroundImage: `url(${record.game.artwork})`,
                      backgroundSize: "cover",
                    }}
                  />

                  <Link to={`/games/${record.game.name}`}>
                    {record.game ? record.game.name : "Unknown"}
                  </Link>
                </div>
              ),
              search: false,
              width: "35%",
            },
            {
              title: "Customer",
              render: (_, record) => (
                <div className="flex">
                  <div className="flex flex-col">
                    <span className="font-medium">{record.customer.name}</span>
                    <span className="text-sm text-gray">{record.customer.email}</span>
                  </div>
                </div>
              ),
              width: "25%",
            },
            {
              title: "Resources",
              render: (_, record) => (
                <div className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray">RAM:</span>
                    <span className="font-medium">{Math.round(record.resources.ram / 1024)} GB</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray">Slots:</span>
                    <span className="font-medium">{record.resources.slots}</span>
                  </div>
                </div>
              ),
              width: "15%",
            },
            {
              title: "Status",
              render: (_, record) => {
                const statusColors = {
                  running: "bg-success/10 text-success",
                  exited: "bg-error/10 text-error",
                  created: "bg-warning/10 text-warning",
                  offline: "bg-error/10 text-error"
                };
                return (
                  <div className={`inline-flex px-3 py-1 rounded-full text-sm font-medium ${statusColors[record.status]}`}>
                    {record.status}
                  </div>
                );
              },
              width: "10%",
            },
            {
              title: "Actions",
              render: (_, record) => {
                return (
                  <div className="space-x-2">
                    <Link to={
                      `/console/${record._id}`
                    }>
                    <Button type="primary">Configure</Button>
                    </Link>
                  </div>
                );
              },
            },
            {
              title: "Container ID",
              render: (_, record) => {
                return (
                  <div className="space-x-2">
                    container: {record.container.substring(0, 8) ?? "n/a"}
                  </div>
                );
              },
            },
          ]}
        />
      </QueryLoader>
    </div>
  );
}
